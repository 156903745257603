*{
  margin: 0;
  padding: 0;
}
.container_cr{
  max-width: 350px;
  min-height: 420px;

  padding: 0;
  border-radius: 10px;
    box-shadow: 3px 5px 35px rgb(86 68 169 / 10%);
    overflow: hidden;
    position: relative;
}
.foo{
 
  display: flex;
  justify-content: space-around;
  margin: 2px;
  margin-top: 70px;
}
.foo-right .strpr .fr{
  color: #2d373c;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
   

}
.strpr{
  padding-left: 25px;
}
.pr-fo{
  color: #ff4838;
  font-size: 20px;
  font-weight: 800;
  line-height: 1;
  width: fit-content;
  margin: 0;
    padding-right: 1px;
}
.container_cr .img img{
 width: 356px;
  height: 241px;
  position: relative;
 
}
.lb_cr p{
  
  
    color: #fff;
    font-size: 16px;
    font-weight: 600;
   
}
.img img{
  width: 100%;

}
.lb_cr{
  background: #ff4838;
  border-radius: 3px;
  top: 200px;
  left: 20px;
  -webkit-clip-path: polygon(9% 0,90% 3%,100% 94%,0 100%);
  clip-path: polygon(9% 0,90% 3%,100% 94%,0 100%);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
 width:200px;
  padding: 9px 32px;
  position: absolute;
  text-transform: capitalize;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
.content{
  margin-top: 40px;
  margin-left: 10px;
  height: max-content;
}
.content h3{
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}
.btn:hover{
  color:#fff !important;

}
@media screen and (max-width: 1200px) {
 
  
 .container_h .wrap_float{
   
  justify-content: center;
  
 }

}
