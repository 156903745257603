/* @import url('https://fonts.googleapis.com/css2?family=Yellowtail&display=swap'); */
.container_swi .leftsw{
   
    display: flex;
  
}
.leftsw{
    width:50%;
    overflow: hidden;
}
.rightsw{
    width: 50%;
    position: relative;
}
.text_swi span {
position: absolute;
color:#ffff;
top:10%;
left:2%;
/* font-family: 'Yellowtail', cursive !important; */

font-weight: 900;
font-size: 75px;

letter-spacing: 0.05em;



transition: all 800ms ease;
transition-delay: 900ms;



font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.text_swi p{
    position: absolute;
color:#ffff;
top:35%;
left:2%;
    margin-top: 20px;
    font-weight: 900;
font-size: 75px;

letter-spacing: 0.05em;



transition: all 800ms ease;
transition-delay: 900ms;



font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: transparent;
    -webkit-text-stroke-width: 2px;
-webkit-text-stroke-color: white;
}
.text_swi{
    position: absolute;
color:#ffff;
top:0%;
left:0%;
    width:100%;
    height:600px;
   background-color: rgba(22, 41, 84, 0.585);
}
.Full{
    display: none;
}


@media screen and (max-width: 1200px) {
    .leftsw,.rightsw,.leftsw img{
        display: none;
    }
    .Full{
        display: block;
        height: 500px;
        overflow: hidden;
    }
    

    
    .text_swi p,.text_swi span{
        left:2%;
        font-size: 50px;
        margin-top: 0;
    
    }
    .text_swi span{
       
        top:13%;
        font-size: 50px;
    }
    
}