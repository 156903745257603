.container_h {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.hd_h h2 {
  color: #2d373c;
  margin-left: 2%;
  font-size: 35px;
  font-weight: 800;
  line-height: 42px;
  width: fit-content;
}
.hd_h {
  display: flex;
  margin-top: 100px;
  flex-wrap: wrap;
  margin-bottom: 60px;
  justify-content: space-between;
}
.mbtn {
  color: aliceblue;
  padding: 20px 40px;
  width: 200px;
  margin-right: 3% !important;
  background-color: orangered !important;
}
.nav_bl {
  padding: 18px 0;
  border-top: 1px solid rgba(45, 55, 60, 0.2);
  border-bottom: 1px solid rgba(45, 55, 60, 0.2);
  margin-top: 40px;
}
.blogc{
    display: flex;
    justify-content: space-between;
}
@media screen and (max-width: 1200px) {
  .hd_h {
    /* justify-content: flex-start; */
  }
  button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.mbtn.css-sghohy-MuiButtonBase-root-MuiButton-root {
    margin-top: 20px;
  }
 .container_h .wrap_float{
   
  justify-content: center;
  
 }
 .blogc{
    flex-wrap: wrap;
    justify-content: center;
 }
}
